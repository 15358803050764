#highScores {
    text-align: center;
    width: 80%;
    margin: auto;
}

td, th, tr {
    border: 3px solid rgb(29, 27, 27);
    padding: 8px;
}

tr:nth-child(even){
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #ddd;
}