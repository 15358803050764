@media all and (min-width: 480px) {
    .Register {
      padding: 60px 0;
    }
  
    .Register form {
      margin: 0 auto;
      max-width: 320px;
    }

    .Register h5 {
      text-align: center;
    }
  }