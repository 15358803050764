.gameScreen {
    align-items: center;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    
}

.gameBoard {
    width: 50vw;
    min-width: 300px;
    min-height: 300px;
    max-width: 640px;
    border: 5px solid black;
    border-radius: 5px;
}

.gamePanel {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    min-width: 300px;
    border: 5px solid black;
    border-radius: 5px;
}

.gamePanel, .gameBoard {
    height: 50vw;
    max-height: 640px;
}

.infoContainer {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chatboxContainer {
    margin: 5px;
    max-width: 300px;
    flex: 1 0 0;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 5px;
    overflow-y: scroll;
}

.gameInfo {
    flex: 1 0 0;
}

.connectScreenContainer h5 {
    text-align: center;
}
.connectScreen {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    border: 5px solid black;
    border-radius: 5px;
    padding: 20px;
}