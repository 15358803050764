.chatContainer {
    border: 2px solid rgb(17, 17, 17);
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 5px;
    word-wrap: break-word;
}

.darker {
    background-color: rgb(136, 90, 90);
}

.chatContainer img {
    float: left;
    max-width: 40px;
    margin-right: 20px;
    border-radius: 50%;
}

.time-right {
    float: right;
    color: #aaa;
}

.send-text {
    padding: 5px;
    border-radius: 5px;
    flex: 5 0 0;
}

.sendBox {
    display: flex;
    flex-direction: row;
}

.send-button {
    padding: 5px;
    flex: 1 0 0;
}

.send-text, .send-button {
    margin: 0;
    align-self: flex-end;
}
  