.items {
    margin: auto;
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-evenly;
}

.balanceDiv {
    padding: 5px;
    text-align: center;
}